// TYPE HEAD - SUGGESTIVE SEARCH
$(document).ready(function() {

	$(document).mouseup(function(e) {
    var dropDown = $(".tt-dropdown-menu");
		var searchBar = $("input.typehead");

    if (!dropDown.is(e.target) && dropDown.has(e.target).length === 0) {
        dropDown.hide();
    }
		if (searchBar.is(e.target)) {
        dropDown.show();
    }
});

	$('input.typehead').on('keyup', function() {
		$.ajax({
		  url: "suggest.php?query=" + this.value,
		  data: {
				query: this.value,
			},
		  success: function(result){
		    // console.log(result);
				if (JSON.stringify(result) != "{}") {
          $('.tt-dropdown-menu').hide();
        }
				$('.tt-dropdown-menu').show();

				var json = JSON.parse(result);

				var returnResults = [];

				for (var i = 0; i < json.length; i++) {
					returnResults.push(json[i]);
				}

				var newHTML = [];
				for (var i = 0; i < returnResults.length; i++) {
				    newHTML.push('<li><a href="' + returnResults[i].url + '">' + returnResults[i].title + '</a></li>');
				}
				$(".result-list").html(newHTML.join(""));
		  },
			error: function(error) {
				console.log(error);
			}
		});
	});

});

// BURGER ICON
function updateMenuButton() {
	$('.burger').find('.burger-icon').toggleClass('is-active');
}

$(document).ready(function() {
	$('.burger').click(function(e){
		e.preventDefault();
		updateMenuButton();

		// $(".nav").toggleClass("show");

		$(".nav").slideToggle(300).toggleClass("show")

		$(this).toggleClass("close");
	});
	$('.mob--search').click(function(e){
		e.preventDefault();
		$('.nav--search').slideToggle(300).toggleClass('show')
	})
});

// ACCORDION NAV
jQuery(document).ready(function(){
	var accordionsMenu = $('.cd-accordion-menu');

	if( accordionsMenu.length > 0 ) {
		accordionsMenu.each(function(){
			var accordion = $(this);
			//detect change in the input[type="checkbox"] value
			accordion.on('change', 'input[type="checkbox"]', function(){
				var checkbox = $(this);
				// console.log(checkbox.prop('checked'));
				( checkbox.prop('checked') ) ? checkbox.siblings('div').attr('style', 'display:none;').slideDown(300): checkbox.siblings('div').attr('style', 'display:block;').slideUp(300);
				( checkbox.prop('checked') ) ? checkbox.siblings('label').addClass('active'): checkbox.siblings('label').removeClass('active');
			});
		});
	}
});
